<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="用户电话">
          <el-input v-model="mobile" placeholder="用户电话"></el-input>
        </el-form-item>
        <el-form-item label="机构">
          <mechanismSelect :mecid.sync="mecid"></mechanismSelect>
        </el-form-item>
        <el-form-item label="查询时间">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <div class="handle-row flex jc-b">
      <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <div class="flex">
        <el-button type="primary" @click="openMany">批量修改</el-button>
        <el-button type="warning" @click="updateTime">提交修改</el-button>
      </div>
      <!-- <p>修改课程时间</p> -->
    </div>
    <div class="flex-center tips">
      {{ tips }}
    </div>
    <div class="flex course-box">
      <div class="course" v-for="(course, index) in DataList" :key="course.id">
        <div class="head flex">
          <el-checkbox
            @change="checkChange(course)"
            v-model="checkList[index]"
          ></el-checkbox>
        </div>
        <div class="info flex-center flex-col">
          <p class="cou-title">{{ course.title }}</p>
          <p>开始：{{ course.start_time }}</p>
          <p>结束：{{ course.end_time }}</p>
        </div>
        <div class="handle flex-center">
          <el-button type="primary" @click="updateSingle(course)" size="small"
            >修改</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog title="修改时间" :visible.sync="timeshow" width="400px">
      <el-form style="margin: 0 auto">
        <el-form-item label="上课日期">
            <el-date-picker
            v-model="sdate"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"

          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-time-picker
            v-model="value1"
            :picker-options="{
              selectableRange: '07:00:00 - 23:30:00',
            }"
            placeholder="任意时间点"
            value-format="HH:mm:ss"

          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-time-picker
            v-model="value2"
            :picker-options="{
              selectableRange: value1 + ' - 23:59:00',
            }"
            placeholder="任意时间点"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="subTime">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="修改时间" :visible.sync="manyshow" width="50%">
      <el-form style="margin: 0 auto">
        <el-form-item label="开始时间">
          <el-time-picker
            v-model="many_value1"
            :picker-options="{
              selectableRange: '07:00:00 - 23:30:00',
            }"
            value-format="HH:mm:ss"
            placeholder="任意时间点"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-time-picker
            v-model="many_value2"
            :picker-options="{
              selectableRange: many_value1 + ' - 23:59:00',
            }"
            value-format="HH:mm:ss"
            placeholder="任意时间点"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            v-model="many_date"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"

          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择星期">
          <el-checkbox-group v-model="week_day" :max="DataList.length">
            <el-checkbox
              v-for="(i, index) in weeks"
              :key="index"
              :label="index"
            >
              {{ i }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <!-- <el-form-item label="是否重复">
          <el-checkbox v-model="is_repeat"></el-checkbox>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="many_subTime">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mechanismSelect from "@/components/select/mechanismSelect.vue";
import userSelect from "@/components/select/userSelect.vue";
export default {
  components: {
    mechanismSelect,
    userSelect,
  },
  data() {
    return {
      nav: { firstNav: "课程管理中心", secondNav: "课程时间调整" },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 86400000;
        },
      },
      mechanism_id: "",
      user_id: "",
      is_loading: false,
      weeks: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      DataList: [],
      Total: 0,
      currentPage: 1,
      date: "",
      sdate: "",
      value1: "",
      value2: "",
      many_date: "",
      many_value1: "",
      many_value2: "",
      timeshow: false,
      currentCourse: {},
      currentCourseList: [],
      start_time: "",
      manyshow: false,
      startTime: "",
      endTime: "",
      week_day: [],
      is_repeat: false,
      checkAll: false,
      checkList: [],
      mecid: "",
      mobile: "",
      tips: "请查询要修改的课程信息",
    };
  },

  mounted() {},

  methods: {
    //全选
    handleCheckAllChange() {
      if (this.checkAll) {
        this.currentCourseList = this.DataList;
        this.checkList = new Array(this.currentCourseList.length).fill(true);
      } else {
        this.currentCourseList = [];
        this.checkList = [];
      }
    },
    //多课程时间修改
    many_subTime() {
      this.currentCourseList = this.currentCourseList.sort(function (a, b) {
        return b.id - a.id;
      });
      this.week_day = this.week_day.sort(function (a, b) {
        return a - b;
      });
      let startDate = new Date(this.many_date);
      let startDay = startDate.getDay();
      let startTime = startDate.getTime();
      let day = 24 * 60 * 60 * 1000;
      let week = 7 * 24 * 60 * 60 * 1000;
      let weekday;
      //每周一天
      if (this.week_day.length == 1) {
        weekday = this.week_day[0];
        if (startDay > weekday) {
          startTime += week;
        }
        this.currentCourseList.forEach((course) => {
          let temp = (weekday - startDay) * day + startTime;
          startTime += week;
          course.start_time =
            new Date(temp).Format("yyyy-MM-dd") + " " + this.many_value1;
          course.end_time =
            new Date(temp).Format("yyyy-MM-dd") + " " + this.many_value2;
          course.is_update = true;
        });
      } else {
        //每周多天
        weekday = this.week_day[0];
        if (startDay > weekday) {
          startTime += week;
        }
        let j = 0;
        let n = Math.ceil(this.currentCourseList.length / this.week_day.length);
        console.log(n);
        for (let k = 0; k < n; k++) {
          for (let i = 0; i < this.week_day.length; i++) {
            let course = this.currentCourseList[j];
            if (!course) {
              break;
            }
            weekday = this.week_day[i];
            console.log(weekday,startDay);
            let temp = (weekday - startDay) * day + startTime;
            course.start_time =
              new Date(temp).Format("yyyy-MM-dd") + " " + this.many_value1;
            course.end_time =
              new Date(temp).Format("yyyy-MM-dd") + " " + this.many_value2;
            course.is_update = true;
            j++;
          }
          startTime += week;
        }
      }
      this.manyshow = false;
    },
    // 打开弹窗
    updateSingle(course) {
      this.currentCourse = course;
      this.timeshow = true;
      this.value1 = course.start_time.split(' ')[1]
      this.value2 = course.end_time.split(' ')[1]
    },
    openMany() {
        if(this.checkList.length == 0){
            this.$message({message:'请选择要修改的课程'})
        }else{
            this.manyshow = true;
            this.many_value1 = this.currentCourseList[0].start_time.split(' ')[1]
            this.many_value2 = this.currentCourseList[0].end_time.split(' ')[1]
        }
    },
    // 单节课改时间
    subTime() {
      if(!this.sdate || !this.value1 || !this.value2){
        this.$message({message:'请选择时间'})
        return
      }
      this.currentCourse.start_time = this.sdate + " " + this.value1;
      this.currentCourse.end_time = this.sdate + " " + this.value2;
      this.currentCourse.is_update = true;
      this.timeshow = false;
    },
    // 复选框操作
    removeItem(id) {
      this.currentCourseList = this.currentCourseList.filter(
        (item) => item.id != id
      );
    },
    checkChange(cour) {
      for (let i = 0; i < this.currentCourseList.length; i++) {
        if (this.currentCourseList[i].id == cour.id) {
          this.currentCourseList.splice(i, 1);
          return;
        }
      }
      this.currentCourseList.push(cour);
    },
    //课程
    getList() {
      let data = {};
      let url = "/user/userAppointment/list/page";
      data = {
        currentPage: 1,
        pageSize: 20,
        offset: 8,
        status: 2,
        master_type: "mechanism_offline",
        mobile: this.mobile || null,
      };
      if (this.mecid) {
        data.mechanism_id = this.mecid;
      }
      data.attend_class_start_time =
        new Date().Format("yyyy-MM-dd") + " 00:00:00";
      data.attend_class_end_time =
        new Date().Format("yyyy-MM-dd") + " 23:59:59";

      if (this.date) {
        data.attend_class_start_time = this.date[0] + " 00:00:00";
        data.attend_class_end_time = this.date[1] + " 23:59:59";
      }
      this.$axios({ url, params: data, method: "get" }).then((res) => {
        if(res.data.code == 0){
          if (res.data.data.rows.length == 0) {
            this.tips = "暂无信息，请检查搜索条件";
          }else{
            this.DataList = res.data.data.rows;
            this.Total = res.data.data.total;
            this.tips = "修改时间后，请勾选要修改的课程，之后点击提交修改按钮提交";
          }
        }
       
      });
    },
    // 提交时间修改
    updateTime() {
      if(this.currentCourseList.length == 0){
        this.$message('请选择要提交的课程')
        return
      }
      let url = "/user/userAppointment/batchUpdateSchoolTime";
      let data = this.currentCourseList.reduce((per,item) => {
        if (item.is_update) {
          per.push( {
            id: item.id,
            start_time: item.start_time,
            end_time: item.end_time,
          })
        }
        return per
      },[]);
      if(data.length == 0){
        this.$message('选中的课程时间没有改变，无需提交')
        return
      }
      this.$axios({ url, data: data, method: "post" }).then(
        (res) => {
            this.$message({message:res.data.message})
            this.getList();
            this.timeshow = false;
            this.manyshow = false;
            this.checkAll = false
            this.checkList = new Array(this.DataList.length).fill(false);
            this.currentCourseList = []
        }
      );
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.course {
  width: 200px;
  height: 120px;
  border: 1px solid rgb(231, 225, 225);
  padding: 10px;
  margin-right: 20px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 4px 4px rgba(20, 20, 20, 0.1);
  backdrop-filter: blur(50px);
  margin-top:20px
}
.head {
  justify-content: flex-end;
}
.info {
  font-size: 14px;
  margin-bottom: 5px;
}
.cou-title {
  font-size: 16px;
}
.handle-row {
  padding: 5px 10px;
  border-bottom: 1px solid #f6f6f6;
}
.container {
  min-height: 80%;
}
.tips {
  font-size: 16px;
  color: rgb(117, 109, 109);
  line-height: 2;
}
.course-box {
  flex-wrap: wrap;
  padding: 20px;
}
</style>